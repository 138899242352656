import React from "react";
import { Link } from "gatsby";

import circleImg from "../images/circleImg.png";

import genericUser from "../images/genericUser.png";
import Head from "../components/head";
import meetStyles from "./ourTeam.module.css";
import { graphql } from "gatsby";

export const query = graphql`
  query {
    officers: allContentfulBio(filter: { program: { eq: "Officer" } }) {
      edges {
        node {
          bioImage {
            file {
              url
            }
          }
          name
          titleOrInstrument
        }
      }
    }
    nyBod: allContentfulBio(filter: { program: { eq: "New York BOD" } }) {
      edges {
        node {
          bioImage {
            file {
              url
            }
          }
          name
          titleOrInstrument
        }
      }
    }
    gaBod: allContentfulBio(filter: { program: { eq: "Georgia BOD" } }) {
      edges {
        node {
          bioImage {
            file {
              url
            }
          }
          name
          titleOrInstrument
        }
      }
    }
    proGABod: allContentfulBio(
      filter: { program: { eq: "UGA Professor and Georgia BOD" } }
    ) {
      edges {
        node {
          bioImage {
            file {
              url
            }
          }
          name
          titleOrInstrument
        }
      }
    }
  }
`;

const OurTeamPage = props => {
  let arr = [];
  arr.push(...props.data.proGABod.edges);
  arr.push(...props.data.gaBod.edges);
  return (
    <>
    <Head title="Our team"></Head>
      <section className={meetStyles.meetTheTeamSection}>
        <h1>Meet the team</h1>
        <p>Meet the people who make TSS happen on a day to day basis. </p>
        <img src={circleImg}></img>
      </section>
      <section className={meetStyles.officers}>
        <h2>officers</h2>
        <div>
          {props.data.officers.edges.map((edge, index) => {
            return (
              <div key={`office${index}`} className={meetStyles.tMItem}>
                <Link to={`/bio/${edge.node.name}`}>
                  {edge.node.bioImage === null ? (
                    <img src={genericUser}></img>
                  ) : (
                    <img src={edge.node.bioImage.file.url}></img>
                  )}
                  <div>
                    <h3>{edge.node.name}</h3>
                    <p>{edge.node.titleOrInstrument}</p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </section>
      <section className={meetStyles.officers}>
        <h2>board of directors (ga)</h2>
        <div>
          {arr.map((edge, index) => {
            return (
              <div key={`gaBod${index}`} className={meetStyles.tMItem}>
                <Link to={`/bio/${edge.node.name}`}>
                  {edge.node.bioImage === null ? (
                    <img src={genericUser}></img>
                  ) : (
                    <img src={edge.node.bioImage.file.url}></img>
                  )}
                  <div>
                    <h3>{edge.node.name}</h3>
                    <p>{edge.node.titleOrInstrument}</p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </section>
      <section className={meetStyles.officers}>
        <h2>board of directors (ny)</h2>
        <div>
          {props.data.nyBod.edges.map((edge, index) => {
            return (
              <div key={`nyBod${index}`} className={meetStyles.tMItem}>
                <Link to={`/bio/${edge.node.name}`}>
                  {edge.node.bioImage === null ? (
                    <img src={genericUser}></img>
                  ) : (
                    <img src={edge.node.bioImage.file.url}></img>
                  )}
                  <div>
                    <h3>{edge.node.name}</h3>
                    <p>{edge.node.titleOrInstrument}</p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default OurTeamPage;
